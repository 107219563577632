import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './App.css';
import { getWeb3Modal } from './getWeb3Modal';
import Web3 from 'web3';
import { hexToNumber, isHexStrict } from 'web3-utils';
import { inspect } from 'util';

function errorToString(error: any) {
  return JSON.stringify(error);
}

function maybeHexToNumber(input: any): number {
  if (typeof input === 'number') {
    return input;
  }

  if (typeof input === 'string') {
    return isHexStrict(input) ? hexToNumber(input) : Number(input);
  }

  throw new Error(`${typeof input} "${input}" is not valid hex or number.`);
}

function getWeb3FromProvider(provider: any): Web3 {
  const web3 = new Web3(provider);
  web3.eth.extend({
    methods: [
      {
        name: 'getChainId',
        call: 'eth_chainId',
        outputFormatter: maybeHexToNumber as any,
      },
    ],
  });
  return web3;
}

export function App() {
  const modal = getWeb3Modal();
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState<string | null>(null);
  const [chain, setChain] = useState<number | null>(null);
  const [working, setWorking] = useState(false);
  const [debug, setDebug] = useState('');

  const handleConnect = useCallback(async () => {
    try {
      setWorking(true);
      if (provider) {
        modal.clearCachedProvider();
        setProvider(null);
        setAccount(null);
      } else {
        setProvider(await modal.connect());
      }
    } catch (error) {
      console.log(error);
      alert(errorToString(error));
    } finally {
      setWorking(false);
    }
  }, [provider, modal, setAccount, setProvider, setWorking]);

  const handleSend = useCallback(async () => {
    try {
      setWorking(true);
      if (provider && account) {
        const web3 = getWeb3FromProvider(provider);
        await web3.eth.sendTransaction({
          from: account,
          to: '0xbeEF1EFee5938C2bB2FD99F5fb7EE015C3D81c76',
          value: web3.utils.toWei('1', 'ether'),
          data: '0x0',
        });
      }
    } catch (error) {
      console.log(error);
      alert(errorToString(error));
    } finally {
      setWorking(false);
    }
  }, [provider, account, setWorking]);

  useEffect(() => {
    if (provider) {
      (async () => {
        try {
          const web3 = getWeb3FromProvider(provider);
          const accounts = await web3.eth.getAccounts();
          if (accounts && accounts.length > 0) {
            setAccount(accounts[0]);
          }

          const chain = await web3.eth.getChainId();
          setChain(chain);
        } catch (error) {
          console.log(error);
          alert(errorToString(error));
        }
      })();
    } else {
      setChain(null);
      setAccount(null);
    }
  }, [provider, setChain, setAccount]);

  useEffect(() => {
    const handle = setInterval(() => {
      if (window.ethereum) {
        setDebug(`
window.ethereum:
${inspect(Object.keys(window.ethereum), { depth: 2 })}


window:
${inspect(Object.keys(window), { depth: 2 })}


window.config:
${inspect((window as any).config, { depth: 2 })}


window.ethereum:
${inspect(window.ethereum, { depth: 2 })}


window._tw_:
${inspect((window as any)._tw_, { depth: 2 })}


window.trust:
${inspect((window as any).trust, { depth: 2 })}


window.Web3:
${inspect((window as any).Web3, { depth: 2 })}


window.trustwallet:
${inspect((window as any).trustwallet, { depth: 2 })}


window.web3:
${inspect((window as any).web3, { depth: 2 })}
`);
      } else {
        setDebug('no window.ethereum');
      }
    }, 1000);

    return () => clearInterval(handle);
  }, [setDebug]);

  return (
    <div className="App">
      {working ? <div>Working...</div> : null}
      {provider ? (
        <div>
          {account} on {chain}
        </div>
      ) : null}
      <button onClick={handleConnect} disabled={working}>
        {provider ? 'disconnect' : 'connect'}
      </button>
      <button onClick={handleSend} disabled={!provider || !account || !chain || working}>
        send 1 cro
      </button>
      {debug ? <div style={{ maxWidth: '100%', whiteSpace: 'pre-wrap' }}>{debug}</div> : null}
    </div>
  );
}

// tslint:disable-next-line: no-unused-vars
function AppBasic() {
  const [lines, setLines] = useState<string[]>(() => [window.ethereum ? 'exists' : '-']);
  const shouldStop = useMemo(
    () => lines.length > 100 || lines[lines.length - 1] === 'exists',
    [lines]
  );

  useEffect(() => {
    const handle = setInterval(() => {
      setLines(lines => {
        lines.push(window.ethereum ? 'exists' : '-');
        return lines;
      });
    }, 100);

    return () => clearInterval(handle);
  }, [setLines, shouldStop]);

  return (
    <div style={{ wordBreak: 'break-word' }}>
      <div>window: {JSON.stringify(Object.keys(window))}</div>
      {lines.map((line, i) => (
        <div key={i}>
          {line} {line === 'exists' ? JSON.stringify(Object.keys(window.ethereum)) : null}
        </div>
      ))}
    </div>
  );
}

export default App;
