import Web3Modal from 'web3modal';
import { DeFiConnector } from 'deficonnect';

let modal: Web3Modal | null = null;

export function getWeb3Modal() {
  if (modal === null) {
    modal = new Web3Modal({
      cacheProvider: false,
      providerOptions: {
        'custom-cdc': {
          display: {
            logo: 'https://app.beefy.com/static/media/crypto.52cfe64b.png',
            name: 'Crypto.com',
            description: 'Crypto.com | Wallet Extension',
          },
          options: {
            supportedChainIds: [1, 25],
            rpc: {
              1: 'https://cloudflare-eth.com', // eth
              25: 'https://evm-cronos.crypto.org', // cronos mainet
            },
            pollingInterval: 5000,
          },
          package: DeFiConnector,
          connector: async (packageConnector, options) => {
            const connector = new packageConnector({
              name: 'Cronos',
              supprtedChainTypes: ['eth'],
              supportedChainTypes: ['eth'],
              eth: options,
              cosmos: null,
            });
            await connector.activate();
            return connector.getProvider();
          },
        },
      },
    });
  }

  return modal;
}
